<template>
    <div>


        <el-dialog title="可下载字段" :visible.sync="isShowModal" :close-on-click-modal="false" :destroy-on-close="true"
            width="600px" custom-class="cus_dialog" @close="handleClose">
            <div style="padding:0 100px;height: 100%; overflow: auto;">
                <div>
                    <el-checkbox label="全选" border v-model="checkAll" @change="handleCheckAllChange">全选</el-checkbox>
                    <el-button type="infor" style="margin-left:110px" icon="el-icon-setting"
                        @click="showZiduanManage()">管理字段</el-button>
                </div>
                <el-checkbox-group v-model="checkList" @change="handleCheckedGroupChange">
                    <div v-for="(item, idx) in tableData" :key="idx">
                        <el-checkbox :label="item.id" :value="item.id" :checked="item.rid">{{ item.title }}</el-checkbox>
                    </div>
                </el-checkbox-group>

                <div style="padding-top:20px;text-align: right; ">

                </div>
            </div>


            <div style="position: absolute; bottom: 10px; right:50px">
                <el-button type="primary" @click="submitForm()" icon="el-icon-checked">保存</el-button>
            </div>


        </el-dialog>



        <el-dialog title="字段管理" :visible.sync="isShowModal2" :close-on-click-modal="false" :destroy-on-close="true"
            width="80%" custom-class="cus_dialog" @close="handleClose">
            <div style="text-align: right; padding-bottom:10px">
                <el-button type="primary" @click="addRow">添加字段</el-button>

            </div>
            <el-table :data="zdList" stripe border width="100%">

                <el-table-column prop="title" label="字段名称" min-width="100" align="center">
                    <template slot-scope="scope">
                        <div v-if="editRow.id != scope.row.id" @click="showROwEdit(scope.row)">
                            {{ scope.row.title }}
                        </div>
                        <div v-else>
                            <el-input v-model="editRow.title"></el-input>
                        </div>
                    </template>

                </el-table-column>



                <el-table-column prop="table_name" label="表名称" min-width="100" align="center">
                    <template slot-scope="scope">
                        <div v-if="editRow.id != scope.row.id" @click="showROwEdit(scope.row)">
                            {{ scope.row.table_name }}
                        </div>
                        <div v-else>
                            <el-input v-model="editRow.table_name"></el-input>
                        </div>
                    </template>
                </el-table-column>

                <el-table-column prop="col_name" label="库字段" min-width="150" align="center">
                    <template slot-scope="scope">
                        <div v-if="editRow.id != scope.row.id" @click="showROwEdit(scope.row)">
                            {{ scope.row.col_name }}
                        </div>
                        <div v-else>
                            <el-input v-model="editRow.col_name"></el-input>
                        </div>
                    </template>
                </el-table-column>

                <el-table-column prop="sort" label="排序号" min-width="100" align="center">
                    <template slot-scope="scope">
                        <div v-if="editRow.id != scope.row.id" @click="showROwEdit(scope.row)">
                            {{ scope.row.sort }}
                        </div>
                        <div v-else>
                            <el-input v-model="editRow.sort"></el-input>
                        </div>
                    </template>
                </el-table-column>

                <el-table-column prop="sort" label="操作" min-width="100" align="center">
                    <template slot-scope="scope">
                        <div style="cursor: pointer;">
                            <el-tag v-if="editRow.id == scope.row.id" type="warning" @click="editRow = {}">取消</el-tag>
                            <el-tag v-else @click="showROwEdit(scope.row)">编辑</el-tag>

                            <el-tag type="success" v-if="editRow.id == scope.row.id" style="margin-left:20px"
                                @click="saveOneRow()">保存</el-tag>


                            <el-tag type="danger" v-else style="margin-left:20px"
                                @click="confirmDelete(scope.row)">删除</el-tag>
                        </div>
                    </template>
                </el-table-column>

            </el-table>

            <div style="position: absolute; top:70px;left:80px;" v-if="selectedRows.length > 0">
                <el-button type="danger" @click="confirmDelete">删除选中行</el-button>
            </div>



        </el-dialog>


    </div>
</template>

<script>

export default {
    props:["roleid"],
    data() {
        return {

            isShowModal: true,
            isShowModal2: false,
            checkAll: false,
            tableData: [],
            checkList: [],
            zdList: [],
            editRow: {},
            selectedRows: []



        }
    },
    mounted() {
        this.getList()
    },
    methods: {
        getList() {

            this.$http.post("/api/get_role_zdlist", { role_id: this.roleid }).then(res => {

                if (res.data && res.data.length > 0) {
                    res.data.map(e => { e.rid ? e.rid = true : e.rid = false })
                    this.tableData = res.data

                }
            })
        },
        handleCheckAllChange() {
            console.log(this.checkAll)
            if (this.checkAll) {
                this.checkList = this.tableData.map(e => { return e.id })

            } else {
                this.checkList = []
            }

        },
        handleCheckedGroupChange() {
            if (this.checkList.length != this.tableData.length) {
                this.checkAll = false
            } else {
                this.checkAll = true
            }
        },
        showZiduanManage() {
            
            this.isShowModal2 = true
            this.$http.post("/api/role_zdlist_all", {}).then(res => {

                if (res.data && res.data.length > 0) {
                    this.zdList = res.data

                }
            })
        },
        handleSelectionChange(e) {
            this.selectedRows = e

        },
        submitForm() {
            this.$http.post("/api/save_role_zd", { role_id:this.roleid,zdids: this.checkList }).then(res => {

                this.$message.success('保存成功')
                
               
            })
        },
        showROwEdit(row) {
            this.editRow = row
        },
        confirmDelete(e) {
            this.$confirm('确认删除?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                this.$http.post("/api/delete_role_ziduan", { ids: [e.id] }).then(res => {

                    this.$message.success('删除成功')
                    this.showZiduanManage();
                })
            }).catch(() => {

            });
        },
        saveOneRow() {
            this.$http.post("/api/role_ziduan_edit", this.editRow).then(res => {

                this.$message.success('保存成功')
                
                this.editRow = {}
                this.showZiduanManage();
            })
        },
        addRow() {
            this.editRow = { title: "", col_name: "", table_name: "", sort: "" }

            this.zdList = [this.editRow].concat(this.zdList)
        },
        handleClose(){
            this.$$parent(this,"zdDialog",false)
        }

    },
}
</script>

<style></style>
